"use client"

import React, {useEffect, useState} from "react"
import {createBrowserClient} from "@supabase/ssr"
import {User} from "@supabase/supabase-js"
import {useContext, useRef} from "react"
import {UpgradeContext} from "@/app/app/(main)/upgrade/UpgradeContext"
import {ButtonPrimary} from "@/components/ui/button"
import {CardComponent, Provider} from "@chargebee/chargebee-js-react-wrapper"
import IconCreditCaptain from "@public/icons/credit-captain.svg"
import IconCircleCheck from "@public/icons/circle-check.svg"
import * as Input from "@/components/ui/input"
import {FiUser} from "react-icons/fi"
import {FaRegCreditCard} from "react-icons/fa"
import {XCircle as XCircleIcon} from "lucide-react"
import {useRouter} from "next/navigation"
import Image from "next/image"

// Import the LoadingSpinner component
import {LoadingSpinner} from "@/components/presentational/tailwind/LoadingSpinner"

// Define window interface with Chargebee properties
declare global {
    interface Window {
        chargebeeInstance: any
    }
}

interface PaymentWrapperProps {
    onBackToPlans: () => void
    onPaymentSuccess: () => void
    isInModal?: boolean
}

// Custom PaymentForm that doesn't rely on useLoggedInUser
function GoPaymentForm({onBackToPlans, onPaymentSuccess, user, isInModal = false}: PaymentWrapperProps & {user: User}) {
    const context = useContext(UpgradeContext)
    if (!context) throw new Error("GoPaymentForm must be used within an UpgradeContext.Provider")

    const {selectedPlan, setSelectedPlan} = context
    const [isComponentLoading, setIsComponentLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const cardRef = useRef(null)
    const [isChargebeeReady, setIsChargebeeReady] = useState(false)
    const [chargebeeAttempts, setChargebeeAttempts] = useState(0)
    const [customerName, setCustomerName] = useState("")
    const [selectedCountry, setSelectedCountry] = useState("US")
    const [showCountryDropdown, setShowCountryDropdown] = useState(false)
    const [zipCode, setZipCode] = useState("")
    const [showZipCode, setShowZipCode] = useState(false)
    const [isLoadingPayment, setIsLoadingPayment] = useState(false)
    const nameInputRef = useRef(null)
    const router = useRouter()

    useEffect(() => {
        const MAX_ATTEMPTS = 25 // Allow more attempts
        let attemptCount = 0

        const checkChargebeeReady = () => {
            attemptCount++

            if (window.chargebeeInstance) {
                console.log("Chargebee instance found, ready to use")
                setIsChargebeeReady(true)
            } else {
                console.log(`Waiting for Chargebee instance... (Attempt ${attemptCount}/${MAX_ATTEMPTS})`)

                if (attemptCount >= MAX_ATTEMPTS) {
                    console.error("Failed to initialize Chargebee after multiple attempts")
                    setErrorMessage("Payment system initialization failed. Please try refreshing the page.")
                } else {
                    setTimeout(checkChargebeeReady, 500)
                }
            }
        }

        checkChargebeeReady()

        // Update the attempts for component rendering
        setChargebeeAttempts(attemptCount)
    }, [])

    useEffect(() => {
        if (user?.user_metadata?.name) setCustomerName(user.user_metadata.name)
    }, [user])

    if (!selectedPlan) return null

    const onCardReady = () => setIsComponentLoading(false)

    function onCardChange(event) {
        let errorsCopy = {}
        let errorMessageCopy

        if (event.error) {
            errorsCopy[event.field] = event.error
            errorMessageCopy = event.error.message
        } else {
            errorsCopy[event.field] = null
            const _errors = Object.values(errorsCopy).filter((val) => val)
            const errorObj = _errors.pop()
            errorMessageCopy = errorObj ? (errorObj as {message: string}).message : ""
        }

        if (errorMessageCopy) setErrorMessage(errorMessageCopy)
    }

    const onPaymentFormSubmit = (e) => {
        e.preventDefault()
        nameInputRef.current.classList.remove("ring-1", "ring-red-500")

        if (!customerName.trim()) {
            setErrorMessage("Please enter your name.")
            nameInputRef.current.focus()
            nameInputRef.current.classList.add("ring-1", "ring-red-500")
            return
        }

        if (!isChargebeeReady) {
            setErrorMessage("Payment system is still initializing. Please try again in a moment.")
            return
        }

        setErrorMessage(null)
        setIsLoadingPayment(true)

        let [firstName, ...otherNames] = customerName.split(" ")
        let lastName = otherNames.length > 0 ? otherNames.join(" ") : firstName

        let tokenizeParams = {
            firstName,
            lastName,
            ...(showZipCode && zipCode ? {billingZip: zipCode} : {}),
            billingCountry: selectedCountry,
            email: user.email
        }

        const chargebeeSettings = {
            site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
            publishableKey: process.env.NEXT_PUBLIC_CHARGEBEE_API_KEY
        }

        if (cardRef.current) {
            cardRef.current
                .tokenize(tokenizeParams)
                .then((data) => {
                    const chargeParams = {
                        token: data.token,
                        firstName,
                        lastName,
                        billingCountry: selectedCountry,
                        billingZip: zipCode,
                        plan: selectedPlan.name,
                        amount: String(selectedPlan.firstMonthMonthlyPrice),
                        upgradeFromSetup: false,
                        site: chargebeeSettings.site
                    }

                    fetch("/api/chargebee/create-subscription", {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify(chargeParams)
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            if (data.success) {
                                setIsLoadingPayment(false)
                                
                                // Dispatch the event for any listeners (but don't rely on it for UI changes)
                                if (typeof window !== "undefined") {
                                    window.dispatchEvent(new Event("payment_success"))
                                }

                                // Call the success callback if provided (for backwards compatibility)
                                if (onPaymentSuccess) {
                                    onPaymentSuccess()
                                }
                                
                                // Regardless of the above, always do a hard redirect to ensure UI refresh
                                window.location.href = "/app/dashboard?upgraded=true"
                            } else if (data.error) {
                                setIsLoadingPayment(false)
                                setErrorMessage(data.error)
                            }
                        })
                })
                .catch((error) => {
                    setIsLoadingPayment(false)
                    setErrorMessage(error.message)
                })
        }
    }

    const {style, classes, locale, placeholder, fonts} = {
        style: {
            base: {
                color: "#0F0F0F",
                fontWeight: "400",
                fontFamily: 'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
                fontSize: "14px",
                fontSmoothing: "antialiased",
                height: "36px",
                padding: "0px",
                iconColor: "#8F8F8F",
                ":-webkit-autofill": {color: "#0F0F0F"},
                ":focus": {color: "#0F0F0F", outline: "none"},
                "::placeholder": {color: "#8F8F8F"},
                ":focus::placeholder": {color: "#8F8F8F"}
            },
            invalid: {
                color: "#df1b41",
                ":focus": {color: "#df1b41"},
                "::placeholder": {color: "#FFCCA5"}
            }
        },
        classes: {
            focus: "focus",
            invalid: "invalid",
            empty: "empty",
            complete: "complete"
        },
        locale: "en",
        placeholder: "Card Number",
        fonts: ["https://fonts.googleapis.com/css?family=Inter:wght@400;500;600;700&display=swap"]
    }

    if (!isChargebeeReady) {
        return (
            <div className="flex w-full justify-center">
                <div className={`w-full ${isInModal ? "max-w-[400px]" : "max-w-[672px]"}`}>
                    <div className="mt-8 text-center">
                        <LoadingSpinner className="mx-auto mb-4" />
                        {chargebeeAttempts > 10 && (
                            <div className="mt-4">
                                <p className="mb-2 text-sm text-gray-600">Taking longer than expected?</p>
                                <button onClick={() => window.location.reload()} className="rounded-md bg-blue-600 px-4 py-2 text-white transition-colors hover:bg-blue-700">
                                    Refresh Page
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full">
            {/* Plan Summary Card - Using exact styling from PaymentForm.tsx */}
            <div className="mt-3 lg:mt-6">
                <div className="flex w-full flex-row gap-5 rounded-[17px] bg-neutral-0 px-5 py-3 shadow-action ring-1 ring-neutral-200 sm:items-center">
                    <div className="flex flex-1 items-center gap-5">
                        <div
                            className={`flex size-10 items-center justify-center rounded-[11px] ${selectedPlan.name === "Basic" ? "bg-primary-normal/[.12]" : selectedPlan.name === "Pro" ? "bg-[#7C3AED]/[.12]" : "bg-[#3A8EED]/[.12]"}`}
                            style={{
                                boxShadow:
                                    selectedPlan.name === "Basic"
                                        ? "0px 0px 0px 0.833333px rgba(18, 160, 47, 0.24), inset 0px 0.416667px 0.416667px #fff"
                                        : selectedPlan.name === "Pro"
                                          ? "0px 0px 0px 0.833333px rgba(124, 58, 237, 0.32), inset 0px 0.416667px 0.416667px #fff"
                                          : "0px 0px 0px 0.833333px rgba(58, 142, 237, 0.32), inset 0px 0.416667px 0.416667px #fff"
                            }}>
                            <IconCreditCaptain className={`size-5 ${selectedPlan.name === "Basic" ? "text-primary-normal" : selectedPlan.name === "Pro" ? "text-[#7C3AED]" : "text-[#0779FF]"}`} viewBox="0 0 12 12" />
                        </div>
                        <div className={`text-heading-s text-neutral-900 ${isInModal ? "sm:text-lg" : ""}`}>{selectedPlan.name} Plan</div>
                    </div>
                    <div className="text-right sm:text-right">
                        <div className={`text-heading-s font-medium text-neutral-900 ${isInModal ? "sm:text-lg" : ""}`}>$0 Today</div>
                        <div className={`text-body-s text-neutral-500 ${isInModal ? "sm:text-sm" : "sm:text-body-s"}`}>then ${selectedPlan.firstMonthMonthlyPrice} per month</div>
                    </div>
                </div>
            </div>

            {/* Payment Form */}
            <form onSubmit={onPaymentFormSubmit} className="mt-4 lg:mt-6 space-y-4">
                <Input.Root>
                    <Input.Wrapper>
                        <Input.Icon icon={FiUser} className="hidden min-[370px]:block" />
                        <Input.Input
                            ref={nameInputRef}
                            type="text"
                            placeholder="Name"
                            required
                            autoComplete="name"
                            maxLength={50}
                            pattern="[A-Za-z\s]*"
                            title="Please enter a valid name. Special characters and numbers are not allowed."
                            onInput={(e) => (e.currentTarget.value = e.currentTarget.value.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase()))}
                            onKeyDown={(e) => {
                                if (!/[a-zA-Z\s]/.test(e.key)) e.preventDefault()
                            }}
                            onChange={(e) => {
                                setCustomerName(e.target.value)
                                if (e.target.value.trim()) nameInputRef.current.classList.remove("ring-1", "ring-red-500")
                            }}
                            value={customerName || ""}
                        />
                    </Input.Wrapper>
                </Input.Root>

                <div className="relative pb-1">
                    <Provider cbInstance={window.chargebeeInstance}>
                        <div className="relative flex h-fit w-full divide-x divide-[#E0E0E0] overflow-hidden rounded-[9px] bg-neutral-0 shadow-action ring-1 ring-neutral-200 transition duration-200 before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:opacity-0 before:ring-1 before:ring-inset before:ring-primary-normal before:transition before:duration-200 focus-within:ring-transparent focus-within:before:opacity-100 hover:ring-transparent hover:before:opacity-100">
                            <div className="group/input-wrapper flex w-full items-center gap-2 px-[9px]">
                                {isChargebeeReady ? (
                                    <div style={{marginLeft: "25px"}} className="relative w-full">
                                        <div className="absolute left-[-25px] top-1/2 -translate-y-1/2 flex items-center justify-center">
                                            <FaRegCreditCard className="size-[17px] shrink-0 select-none text-neutral-500" />
                                        </div>
                                        <CardComponent
                                            ref={cardRef}
                                            className={`h-9 w-full content-center bg-transparent bg-none text-body-s text-neutral-900 caret-primary-normal placeholder:text-neutral-500 focus:outline-none focus:outline-0 ${isComponentLoading ? "opacity-0" : "opacity-100"}`}
                                            styles={style}
                                            classes={classes}
                                            locale={locale}
                                            placeholder={placeholder}
                                            fonts={fonts}
                                            onChange={onCardChange}
                                            onReady={onCardReady}
                                            icon={false}
                                        />
                                    </div>
                                ) : (
                                    <div className="flex h-9 w-full items-center">
                                        <div className="flex w-full items-center place-content-between p-0">
                                            <div className="h-5 w-44 animate-pulse rounded-md bg-neutral-200"></div>
                                            <div className="h-5 w-10 animate-pulse rounded-md bg-neutral-200"></div>
                                            <div className="h-5 w-20 animate-pulse rounded-md bg-neutral-200"></div>
                                        </div>
                                    </div>
                                )}
                                {isChargebeeReady && isComponentLoading && (
                                    <div className="absolute inset-0 flex h-9 w-full px-3 items-center bg-neutral-0">
                                        <div className="flex w-full items-center place-content-between p-0">
                                            <div className="h-5 w-44 animate-pulse rounded-md bg-neutral-200"></div>
                                            <div className="h-5 w-10 animate-pulse rounded-md bg-neutral-200"></div>
                                            <div className="h-5 w-20 animate-pulse rounded-md bg-neutral-200"></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Provider>
                </div>

                {errorMessage && (
                    <div className="border-1 mt-5 rounded-xl border-red-500 bg-red-100 p-2 px-[9px]">
                        <div className="flex items-center justify-between">
                            <div className="flex">
                                <XCircleIcon className="h-5 w-5 text-red-700" aria-hidden="true" />
                                <div className="ml-3">
                                    <p className="text-sm text-red-700">{errorMessage}</p>
                                </div>
                            </div>
                            <button onClick={() => setErrorMessage(null)} className="align-middle">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-7 w-7 rounded-full p-1 text-red-600 transition-all duration-200 ease-in-out hover:bg-red-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                )}

                <ButtonPrimary disabled={isComponentLoading || isLoadingPayment} type="submit" className="w-full" size="large" leadingIcon={isLoadingPayment ? undefined : IconCircleCheck}>
                    {isLoadingPayment ? (
                        <div className="relative inline-flex">
                            <LoadingSpinner className="text-blue-white my-auto" />
                            <span>Processing...</span>
                        </div>
                    ) : (
                        "Complete Enrollment"
                    )}
                </ButtonPrimary>

                <div className="pt-3 text-footnote text-neutral-500">
                    A temporary hold will be placed on your account to verify your payment method. This is not a charge, and no funds will be withdrawn until services begin and your first setup fee is charged on{" "}
                    {new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString()}.
                </div>

                {/* Use the same Powered by Stripe styling */}
                <div className="flex">
                    <div
                        className="flex items-center gap-1 rounded-[7px] bg-neutral-100 px-1.5 py-1 text-caption-s text-neutral-500"
                        style={{
                            boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.12), inset 0px 0.5px 0.5px #fff"
                        }}>
                        Powered by
                        <Image src="/images/stripe.svg" alt="Stripe logo" className="h-3 object-contain" width={32} height={12} />
                    </div>
                </div>
            </form>
        </div>
    )
}

// Custom wrapper for the PaymentForm to handle authentication edge cases
export default function PaymentWrapper({onBackToPlans, onPaymentSuccess, isInModal = false}: PaymentWrapperProps) {
    const [user, setUser] = useState<User | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const supabase = createBrowserClient(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
    )

    // Check if user is authenticated and handle loading state
    useEffect(() => {
        const checkAuth = async () => {
            try {
                // Verify authentication with getUser first (contacts Supabase Auth server)
                const { data: userData, error: userError } = await supabase.auth.getUser();
                
                if (userError) {
                    console.error("Auth check error in PaymentWrapper:", userError);
                    return;
                }

                // If user is authenticated, set the user
                if (userData?.user) {
                    setUser(userData.user);
                }
            } catch (error) {
                console.error("Auth check error in PaymentWrapper:", error);
            } finally {
                setIsLoading(false);
            }
        };

        checkAuth();

        // Also set up an auth state change listener
        const {
            data: {subscription}
        } = supabase.auth.onAuthStateChange(async (event, session) => {
            if (session?.user) {
                // Verify user with getUser to ensure secure authentication
                const { data } = await supabase.auth.getUser();
                if (data.user) {
                    setUser(data.user);
                }
            } else {
                setUser(null);
            }
        });

        return () => {
            subscription.unsubscribe()
        }
    }, [supabase.auth])

    // Listen for payment success event
    useEffect(() => {
        // Listen for payment success event
        const handlePaymentEventSuccess = () => {
            if (onPaymentSuccess) {
                onPaymentSuccess()
            }
        }

        // Add a custom event listener for payment success
        window.addEventListener("payment_success", handlePaymentEventSuccess)

        // Clean up
        return () => {
            window.removeEventListener("payment_success", handlePaymentEventSuccess)
        }
    }, [onPaymentSuccess])

    // Show loading state
    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center p-6 w-full">
                <div className="animate-pulse text-center flex flex-row items-center gap-3 justify-center">
                    <LoadingSpinner className="m-auto" />
                    <p className="text-gray-500">Loading payment options...</p>
                </div>
            </div>
        )
    }

    // Show an error if there's no user
    if (!user) {
        return (
            <div className="flex flex-col items-center justify-center rounded-lg bg-red-50 p-6">
                <div className="text-center">
                    <h3 className="mb-2 font-medium text-red-600">Authentication Error</h3>
                    <p className="mb-4 text-gray-700">There was a problem with your account. Please try refreshing the page.</p>
                    <button onClick={() => window.location.reload()} className="rounded-md bg-red-600 px-4 py-2 text-white transition-colors hover:bg-red-700">
                        Refresh Page
                    </button>
                </div>
            </div>
        )
    }

    // When authenticated, render our custom GoPaymentForm that doesn't use useLoggedInUser
    return <GoPaymentForm user={user} onBackToPlans={onBackToPlans} onPaymentSuccess={onPaymentSuccess} isInModal={isInModal} />
}
