'use client';

import React from 'react';
import PageSignUp from '@/app/(auth)/sign-up/Signup';

interface SignupFormProps {
  onSignupSuccess: () => void;
}

export default function SignupForm({ onSignupSuccess }: SignupFormProps) {
  return (
    <PageSignUp 
      onSignupSuccess={onSignupSuccess}
      hideReviewsPanel={true}
      hideLoginLink={true}
      customLogoPath="/img/creditcaptain-logo-wide.svg"
      buttonText="Create account"
      removeTopPadding={true}
    />
  );
} 