'use client';

import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { createBrowserClient } from '@supabase/ssr';
import Script from 'next/script';
import SignupForm from './SignupForm';
import { UpgradeContext, Plan } from '@/app/app/(main)/upgrade/UpgradeContext';
import { plans } from '@/constants';
import { chargebeeFrontendSettingsForSite } from '@/utils/helpers';
import PageChoosePlan from '@/app/app/(main)/upgrade/UpgradePage';
import PaymentWrapper from './PaymentWrapper';
import { LoadingSpinner } from '@/components/presentational/tailwind/LoadingSpinner';

// Set the Chargebee site
const chargebeeSite = 'creditcaptain';

export default function GoOnboarding() {
  // Find the Turbo plan from constants
  const turboPlan = plans.find(p => p.name === 'Turbo') || null;
  
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(turboPlan);
  const [paymentFormKey, setPaymentFormKey] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [authConfirmed, setAuthConfirmed] = useState(false);
  const router = useRouter();
  
  // Initialize Supabase client
  const supabase = createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
  );
  
  // Check authentication status on mount
  useEffect(() => {
    const checkAuth = async () => {
      try {
        // First verify user with getUser (contacts Supabase Auth server)
        const { data: userData, error: userError } = await supabase.auth.getUser();
        
        if (userError) {
          console.error('Auth check error:', userError);
          setIsLoading(false);
          return;
        }
        
        // Only get session after verifying user is authenticated
        if (userData.user) {
          const { data: sessionData } = await supabase.auth.getSession();
          console.log('User is authenticated, showing plan selection');
          setAuthConfirmed(true);
          setCurrentStep(2);
        } else {
          console.log('User is not authenticated, showing signup form');
          setAuthConfirmed(false);
        }
      } catch (error) {
        console.error('Failed to check authentication:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    checkAuth();
    
    // Set up auth state listener to catch authentication changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('Auth state changed:', event, session);
        if (session?.user) {
          // Verify the session with getUser
          const { data } = await supabase.auth.getUser();
          if (data.user) {
            setAuthConfirmed(true);
          }
        } else {
          setAuthConfirmed(false);
        }
      }
    );
    
    return () => {
      subscription.unsubscribe();
    };
  }, [supabase.auth]);
  
  // Handler for successful signup - adds verification step
  const handleSignupSuccess = async () => {
    try {
      // Verify authentication with getUser first for security
      const { data: userData } = await supabase.auth.getUser();
      
      if (userData?.user) {
        console.log('Authentication confirmed after signup');
        setAuthConfirmed(true);
        setCurrentStep(2);
      } else {
        console.error('Authentication not confirmed after signup');
        // Force a page reload to ensure authentication is properly established
        window.location.reload();
      }
    } catch (error) {
      console.error('Error verifying authentication after signup:', error);
    }
  };
  
  // Handler for successful payment
  const handlePaymentSuccess = () => {
    router.push('/app');
  };
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-[200px]">
        <div className="text-center">
          <LoadingSpinner className="mx-auto mb-4" />
        </div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen">
      {currentStep === 1 ? (
        /* Step 1: Signup - Simple centered layout without sidebar or header */
        <div className="flex justify-center items-center min-h-screen relative">
          {/* Decorative SVG elements */}
          <img
            src="/images/create-new-account-shape-left.svg"
            alt=""
            width={380}
            height={380}
            className="pointer-events-none absolute -top-10 left-[67px] hidden max-w-none object-contain md:block z-0"
          />
          <img
            src="/images/create-new-account-shape-right.svg"
            alt=""
            width={624}
            height={497}
            className="pointer-events-none absolute bottom-11 right-[-212px] hidden max-w-none object-contain md:block z-0"
          />
          
          {/* Signup form (centered) */}
          <div className="w-full max-w-md z-10 relative">
            <SignupForm onSignupSuccess={handleSignupSuccess} />
          </div>
        </div>
      ) : (
        /* Step 2: Plan Selection */
        <UpgradeContext.Provider value={{
          selectedPlan,
          setSelectedPlan: (plan: Plan | null) => {
            setSelectedPlan(plan || turboPlan);
            setPaymentFormKey(prev => prev + 1);
          }
        }}>
          <div className="flex flex-col lg:flex-row min-h-screen">
            {/* Left section - plan selection */}
            <div className="w-full lg:w-[calc(100%-508px)]">
              <div className="p-6 pb-5 lg:p-8 lg:pr-6">
                <div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:gap-4">
                  <img src="/images/logo-flat.svg" alt="" className="size-8" />
                  <div>
                    <div className="text-display-m text-neutral-800">
                      You're one step closer to a better score!
                    </div>
                    <p className="mt-2 text-body-s text-neutral-600 sm:mt-0">
                      Get started for $0 today.
                    </p>
                  </div>
                </div>
                
                {/* Plan selection component */}
                <div className="mt-8">
                  <PageChoosePlan hideHeader={true} />
                </div>
              </div>
            </div>
            
            {/* Right section - payment form */}
            <div className="rounded-3xl bg-neutral-100 lg:rounded-none">
              <div className="mx-auto flex w-full max-w-[508px] flex-col gap-5 p-6 sm:gap-6 sm:p-8">
                <div className="text-heading-m text-neutral-600">
                  Enter payment details
                </div>
                
                <PaymentWrapper 
                  key={paymentFormKey}
                  onBackToPlans={() => setSelectedPlan(null)} 
                  onPaymentSuccess={handlePaymentSuccess}
                  isInModal={false}
                />
              </div>
            </div>
          </div>
        </UpgradeContext.Provider>
      )}
      
      {/* Only load Chargebee when user reaches payment step (step 2) */}
      {currentStep === 2 && chargebeeSite && (
        <>
          <Script
            src="https://js.chargebee.com/v2/chargebee.js"
            strategy="lazyOnload"
            onLoad={() => {
              console.log('Chargebee script has loaded successfully');
              try {
                // Initialize Chargebee instance as soon as script loads
                window.chargebeeInstance = window.Chargebee.init({
                  site: chargebeeSite,
                  publishableKey: chargebeeFrontendSettingsForSite(chargebeeSite).publishableKey
                });
                console.log('Chargebee instance initialized successfully');
              } catch (error) {
                console.error('Error initializing Chargebee:', error);
              }
            }}
            onError={() => {
              console.error('Failed to load Chargebee script');
            }}
          />
        </>
      )}
    </div>
  );
}

// Add TypeScript declaration for Chargebee
declare global {
  interface Window {
    Chargebee: any;
    chargebeeInstance: any;
  }
} 